import React, { FunctionComponent, useEffect, useState } from 'react'
import { makeStyles, Snackbar, Typography } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SendIcon from '@material-ui/icons/Send'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import MessengerService from '../../../services/messenger.service'
import {
  ConversationMessage,
  ConversationUserDetails,
} from '../../../store/Messenger/types'
import { formatBytes, getBase64 } from '../../../helpers/utils'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'
import { Alert, Color } from '@material-ui/lab'

let attachmentLimitInMB = 5
if (process.env.REACT_APP_MESSENGER_ATTACHMENT_LIMIT_IN_MB) {
  attachmentLimitInMB = parseInt(
    process.env.REACT_APP_MESSENGER_ATTACHMENT_LIMIT_IN_MB
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    bottom: 0,
  },
  sendButtonRoot: {
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    position: 'absolute',
    right: '20px',
    top: '-20px',
  },
  sendButtonStartIcon: {
    marginLeft: '8px',
  },
  attachmentName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '200px',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
  },
  attachmentContainer: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fileInput: {
    display: 'none',
  },
  attachedFile: {
    background: '#dee2e6',
    padding: '2px 10px 2px 0',
    fontSize: '13px',
    height: '22px',
    display: 'flex',
    alignItems: 'center',
  },
}))

type MessengerChatProps = {
  channelId: number
  peerId: number
}

const MessengerChat: FunctionComponent<MessengerChatProps> = ({
  channelId,
  peerId,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [conversationMessages, setConversationMessages] = useState<
    ConversationMessage[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingUserDetails, setLoadingUserDetails] = useState<boolean>(true)
  const [messageSending, setMessageSending] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messageError, setMessageError] = useState<boolean>(false)
  const [attachment, setAttachment] = useState<File | null>(null)
  const [notification, setNotification] = useState<boolean>(false)
  const [userDetails, setUserDetails] =
    useState<ConversationUserDetails | null>(null)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [snackbarData, setSnackbarData] = useState<{
    severity: Color
    message: string
  } | null>(null)

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageError(false)
    setMessage(event.target.value)
  }

  const handleNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotification(event.target.checked)
  }

  useEffect(() => {
    const fetchConversationMessages = async () => {
      try {
        const conversationMessagesResponse =
          await MessengerService.getConversationMessages(channelId, peerId)
        setConversationMessages(conversationMessagesResponse.data.messages)
      } catch (error) {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }

    const fetchUserDetails = async () => {
      setLoadingUserDetails(true)
      try {
        const userDetailsResponse =
          await MessengerService.getConversationUserDetails(peerId)
        setUserDetails(userDetailsResponse.data)
      } catch (error) {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()

        console.warn(_content)
      } finally {
        setLoadingUserDetails(false)
      }
    }

    fetchConversationMessages()
    const interval = setInterval(() => {
      fetchConversationMessages()
    }, 3000)

    fetchUserDetails()
    setAttachment(null)
    setNotification(false)
    setMessage('')

    return () => clearInterval(interval)
  }, [channelId, peerId])

  const handleMessageSend = async () => {
    setMessageError(false)
    if (message.length === 0 && !attachment) {
      setMessageError(true)
      setSnackbarData({
        severity: 'error',
        message: t('messenger.errors.emptyMessageOrNoAttachment'),
      })
      setOpenSnackbar(true)
      return
    }

    let attachmentFilename = null
    let attachmentContent = null

    try {
      setMessageSending(true)

      if (attachment) {
        attachmentFilename = attachment.name
        const data = await getBase64(attachment)

        if (typeof data === 'string') {
          attachmentContent = data.split(',')[1]
        }
      }

      await MessengerService.sendConversationMessage(
        channelId,
        peerId,
        message.length === 0 ? null : message,
        attachmentFilename,
        attachmentContent,
        notification
      )

      const conversationMessagesResponse =
        await MessengerService.getConversationMessages(channelId, peerId)
      setConversationMessages(conversationMessagesResponse.data.messages)
    } catch (error) {
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString()

      console.warn(_content)
    } finally {
      setMessageSending(false)
      setAttachment(null)
      setNotification(false)
      setMessage('')
    }
  }

  const getAttachment = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    attachmentUuid: string
  ) => {
    try {
      const attachmentResponse = await MessengerService.getMessageAttachment(
        attachmentUuid
      )
      const linkSource = `data:${attachmentResponse.data.mimeType};base64,${attachmentResponse.data.base64Content}`
      const downloadLink = document.createElement('a')
      const fileName = attachmentResponse.data.filename

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    } catch (error) {
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString()

      console.warn(_content)
    } finally {
    }
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const fileSize = event.target.files[0].size / 1024 / 1024
      if (fileSize > attachmentLimitInMB) {
        setSnackbarData({
          severity: 'error',
          message: t('messenger.errors.attachmentSizeExceeded', {
            limit: attachmentLimitInMB,
          }),
        })
        setOpenSnackbar(true)
      } else {
        setAttachment(event.target.files[0] as File)
      }
      event.target.value = ''
    }
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackbar(false)
  }

  const chatBubbles = conversationMessages.map((obj) => (
    <div
      className={`talk-bubble tri-right ${
        obj.isMessageAuthor ? 'right' : 'left'
      }-top ${obj.isMessageAuthor ? 'right' : 'left'}`}
      key={obj.messageId}
    >
      <div
        style={{
          position: 'absolute',
          left: '0',
          top: '-20px',
          fontSize: '12px',
        }}
      >
        {obj.messageCreated.replace('T', ' ')}
      </div>
      <div
        style={{
          position: 'absolute',
          right: '0',
          top: '-20px',
          fontSize: '12px',
        }}
      >
        {obj.isMessageAuthor
          ? 'Ja'
          : `${obj.authorFirstname} ${obj.authorLastname}`}
      </div>
      {obj.isNotification && (
        <div
          className={`${
            obj.isMessageAuthor ? 'notification-left' : 'notification-right'
          }`}
        >
          <NotificationsIcon style={{ fontSize: '20px', color: '#d70000' }} />
        </div>
      )}
      <div className="talktext">
        <Typography variant="body2">{obj.messageContent}</Typography>
        {obj.attachmentUuid && (
          <div
            className={classes.attachmentContainer}
            onClick={(e) => getAttachment(e, obj.attachmentUuid || '')}
          >
            <AttachFileIcon style={{ fontSize: '12px' }} />{' '}
            <Typography className={classes.attachmentName} variant="caption">
              {obj.attachmentName}
            </Typography>
          </div>
        )}
        {obj.attachmentSize && <small>{formatBytes(obj.attachmentSize)}</small>}
      </div>
    </div>
  ))

  return (
    <>
      <div
        style={{
          height: '105px',
          backgroundColor: '#e6e6e6',
          padding: '10px',
        }}
      >
        {loadingUserDetails && <CircularProgress />}
        {!loadingUserDetails && userDetails && (
          <ListItem style={{ marginTop: '8px' }}>
            <ListItemAvatar>
              <Avatar
                style={{
                  border:
                    userDetails.userTypeId === 2
                      ? '1px solid #d70000'
                      : '1px solid #e0e4e7',
                  width: '50px',
                  height: '50px',
                }}
                src={`data:${userDetails.imageMimeType};base64,${userDetails.imageContent}`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${userDetails.firstname} ${userDetails.lastname}`}
              secondary={`${userDetails.userTypeName} ${
                userDetails.regionName ? userDetails.regionName : ''
              }`}
            />
          </ListItem>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          height: `calc(100% - 106px - 102px - 54px ${
            attachment ? '- 22px' : ''
          })`,
          flexDirection: 'column-reverse',
          overflowY: 'auto',
          borderBottom: '1px solid #ced4da',
        }}
      >
        {loading && <CircularProgress />}
        {!loading && (
          <div className={classes.container}>
            {conversationMessages.length > 0 && chatBubbles}
          </div>
        )}
      </div>
      <TextField
        id="standard-multiline-static"
        multiline
        placeholder={t('messenger.writeMessage')}
        helperText={`${message.length}/1000`}
        rows={2}
        maxRows={3}
        fullWidth
        style={{ padding: '5px 10px' }}
        inputProps={{ maxLength: 1000 }}
        InputProps={{
          style: {
            height: '70px',
          },
        }}
        value={message}
        onChange={handleMessageChange}
        error={messageError}
      />
      {attachment && (
        <div className={classes.attachedFile}>
          <IconButton aria-label="delete" onClick={() => setAttachment(null)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <AttachFileIcon style={{ fontSize: '12px' }} />{' '}
          <Typography className={classes.attachmentName} variant="caption">
            {attachment.name}
          </Typography>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          position: 'relative',
        }}
      >
        <input
          className={classes.fileInput}
          id="contained-button-file"
          type="file"
          onChange={onFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            component="span"
            size="small"
            color="default"
            startIcon={<AttachFileIcon />}
            style={{ textTransform: 'capitalize' }}
          >
            {t('messenger.attachment')}
          </Button>
        </label>
        <FormControlLabel
          control={
            <Checkbox
              checked={notification}
              onChange={handleNotificationChange}
              name="notification"
              color="primary"
            />
          }
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.8125rem',
              }}
            >
              <NotificationsIcon style={{ fontSize: '20px' }} />{' '}
              {t('messenger.notification')}
            </div>
          }
          style={{ marginLeft: '10px' }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SendIcon style={{ fontSize: '30px' }} />}
          classes={{
            root: classes.sendButtonRoot,
            startIcon: classes.sendButtonStartIcon,
          }}
          onClick={handleMessageSend}
          disabled={messageSending}
        />
      </div>

      {snackbarData && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarData.severity}>
            {snackbarData.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default MessengerChat
